import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './index.scss';

const dropList = [
  {
    link: '/detail?id=0',
    title: '智能座舱设计方案',
  },
  {
    link: '/detail?id=1',
    title: '座舱自动化测试设计方案',
  },
  {
    link: '/detail?id=2',
    title: '智能车联网设计方案',
  },
  {
    link: '/detail?id=3',
    title: '车路协同设计方案',
  },
];

const Header = () => {
  const navigate = useNavigate();
  const location = window.location;
  const [showParter, setShowPartner] = useState(false);

  const handlePartner = () => {
    window.location.href = './parter.html';
  };

  useEffect(() => {
    setShowPartner(window.location.href.includes('prd-'));
  });

  return (
    <div className="header">
      <Link to="/index">
        <span className="header-logo logo"></span>
      </Link>
      <div className="header-menus">
        <NavLink to="/index" className="header-menus_item">
          首页
        </NavLink>
        <span className={`header-menus_item ${window.location.href.includes('detail') ? 'link-active' : ''}`}>
          设计方案与产品
          <ul className="header-menus_item-dropList">
            {dropList.map((item) => (
              <li className={`dropList-item`} key={item.link}>
                <Link to={item.link} className={`${window.location.href.includes(item.link) ? 'active' : ''}`}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </span>
        <span
          onClick={() => {
            if (location.href.includes('#/index')) {
              document.querySelector(`#hot_prd`).scrollIntoView();
              return;
            }
            navigate('/index?scrollDom=hot_prd');
          }}
          className={`header-menus_item  ${window.location.href.includes('prd-shop') ? 'link-active' : ''}`}
        >
          热门商品
        </span>
        <NavLink to="/prd-list" className="header-menus_item">
          配件商城
        </NavLink>
        {showParter && (
          <>
            <span onClick={handlePartner} className="header-menus_item">
              商家入驻
            </span>
          </>
        )}
      </div>
      <div className="header-action">
        <div className="header-action_login">
          <NavLink to="/login" className="header-action_item">
            <i className="icon-wode-wode iconfont"></i>
            登录
          </NavLink>
        </div>
        <div className="header-action_register">
          <NavLink to="/login" className="header-action_item">
            立即注册
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
