import { Link } from 'react-router-dom'
import './index.scss'

const Footer = () => {
  const handleGoTop = () => {
    window.scrollTo({top: 0})
  }
  return (
   <div className='footer-wrap'>
    <div className='footer-contact'>
      <p className=''>联系我们，定制您的专属方案</p>
      <Link to="/login">立即咨询</Link>
    </div>
     <footer className="footer">
      <div className="footer-left">
        <div className="footer-left_name">老司机科技有限公司</div>
      </div>
      <div className='footer-right'>
        <p>地址：中国（上海）自由贸易试验区世博村路231号2单元3层328室</p>
        <p>
          邮箱：<a className='mail' href="mailto:luomj@bxlsj.com">luomj@bxlsj.com</a>
          &nbsp;&nbsp;
          电话：<a className='mail' href="tel:021-50541733">021-50541733</a>
        </p>
      </div>
      <div className='footer-bottom'>
        <span className="footer-left_copy">Copyright © 老司机科技有限公司 All Rights Reserved.</span>
        <a className="footer-left_mail" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备2024075652号-1</a>
      </div>
      <div className='footer_go-top' onClick={handleGoTop}>TOP</div>
    </footer>
   </div>
  )
}

export default Footer